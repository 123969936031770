.pos-container {
    display: flex;
    height: 100vh;
  }
  
  .product-selection {
    flex: 2;
    overflow-y: auto;
    border-right: 1px solid #ccc;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-left: 20px;
    padding: 20px;
  }
  
  .transaction-summary {
    flex: 1;
    padding: 20px;
    border-left: 1px solid #ccc;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-left: 20px;
    overflow-y: auto;
    flex-direction: column;
  }

.transaction-summary h2 {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.5em;
    margin-top: 20px;
}

.product-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.cart-item {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.cart-item:last-child {
    border-bottom: none;
}

.cart-item img {
    width: 30px;
    height: auto;
    margin-right: 10px;
}

.cart-item div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 10px;
}

.cart-item p {
    margin: 5px;
    color: #555;
}

.cart-item button {
    border: none;
    height: auto;
    margin: 0 5px;
    cursor: pointer;
}

.cart-item span {
    width: 25px;
    height: auto;
    margin: 0 5px;
    padding: 0 15px;
}

.cart-item button:hover {
    background-color: #ddd;
}

.product-grid, .recommendation-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0 15px;
}

.product-card {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
    width: 25%;
    cursor: pointer;
}

.product-image {
    width: 100%;
    height: auto;
    margin-bottom: 8px;
}

.product-info {
    font-size: 0.9em;
}

.product-name {
    margin-bottom: 4px;
}

.product-price {
    font-weight: bold;
}

.input-search-pos {
    width: 75%;
    margin: 0 15px 15px;
    padding: 1%;
}

.summary-section {
    margin-top: auto;
    padding-top: 10px;
    border-top: 1px solid #eee;
}

.summary-section p {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
}

.summary-section p label {
    font-weight: normal;
}

.summary-section p span {
    font-weight: bold;
}

.payment-method-button button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 8px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.payment-method-button button:hover {
    background-color: #0056b3;
}

.complete-transaction-button button {
    width: 100%;
    padding: 15px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.complete-transaction-button button:hover {
    background-color: #218838;
}

.payment-details {
    display: flex;
    flex-direction: column; /* Align items vertically */
}

.payment-details h3 {
    margin: 2px 0px;
}

.payment-details p {
    margin: 2px 0px;
}
  
  .payment-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    border-bottom: 1px solid #eee;
  }
  
  .payment-type {
    text-transform: capitalize;
  }
  
  .payment-amount {
    font-weight: bold;
  }
  
  .payment-total {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
  }

  .cancel-transaction-button {
    width: 100%;
    padding: 15px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    /*margin-top: 10px;  Give some space between the buttons */
  }
  
  .cancel-transaction-button:hover {
    background-color: #c82333;
  }

  .payment-methods {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* This will create 3 equal columns */
    gap: 10px; /* Adjust the gap between buttons if needed */
  }

.complete-transaction-button,
.cancel-transaction-button {
  display: inline-block; /* Align buttons side by side */
  width: 48%; /* Slightly less than half to account for any padding/margin */
  /*margin-right: 2%;  Adjust the spacing between the buttons */
}

.complete-cancel-buttons-container {
    display: flex;
    justify-content: space-between;
  }
  /* Additional styling as needed */

.payment-method-detail {
    margin-bottom: 15px; /* Add some space below each payment method section */
}

.client-selection-pos {
    text-align: left;
    cursor: pointer;
}

.complete-transaction-button.disabled-button {
    background-color: #ccc !important; /* Ensure gray color */
    color: #666 !important; /* Dimmed text color */
    pointer-events: none !important; /* Disable interaction */
    cursor: not-allowed !important; /* Show "not-allowed" pointer */
}